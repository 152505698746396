import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  Grid,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  InfoBox,
  InfoText,
  StyledAnchor,
  OutlindedFieldContainer,
  StyledButton,
  FormText,
  FileContainer,
  DeleteIcon,
  StyledFormGroup,
  OpenHourIcon,
  EmailIcon,
  MobileIcon,
  MapIcon,
  QrCodeIcon,
  StyledIFrame,
  FieldContainer,
  StyledTextField,
  StyledAlert,
  QRTitle,
  QrCodeBox,
  QrCodeContainer,
} from "./styles";
import QrCode from "./QrCodeImage";
import { colors } from "../../styles/variables";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { getFiles } from "../toBase64File";
import { callEmailAPI } from "../emailSend";
import BannerImage from "../BannerImg";
import banner from "../../images/021_Gippsland_banner.jpg";

const GreenCheckbox = withStyles({
  root: {
    color: "#828282",
    "&$checked": {
      color: "#158da7",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        width: "100%",
      },
    },
    top: {
      color: `${colors.dentBlue}`,
      animationDuration: "550ms",
      width: "1rem !important",
      height: "1rem !important",
      margin: "0 0.5rem 0 0",
    },
  })
);
const Contact = () => {
  const [isUploadFiles, setIsUploadFiles] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openSucceed, setOpenSucceed] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openFileSize, setOpenFileSize] = useState(false);
  const [isAppointment, setIsAppointment] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();

  const mobileProps = {
    maxLength: 10,
  };
  const nameProps = {
    maxLength: 40,
  };
  const messageProps = {
    maxLength: 1000,
  };

  const onSubmit = async (data) => {
    setSubmitting(true);

    data.requestPage = "Contact";
    let files;
    await getFiles(uploadFile).then((customJsonFile) => {
      files = customJsonFile;
    });
    data.files = files;
    const maxFileSize = 4194304;
    let totalFileSize = 0;
    files.map((item) => (totalFileSize = totalFileSize + item.fileSize));

    if (totalFileSize < maxFileSize) {
      await callEmailAPI(data).then(({ isSubmitting, response }) => {
        setSubmitting(isSubmitting);
        response.ok ? setOpenSucceed(true) : setOpenError(true);
      });
    } else {
      setOpenFileSize(true);
      setSubmitting(false);
      setUploadFile([]);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone();

  const deleteFile = (name) => {
    const index = uploadFile.findIndex((file) => file.name === name);

    uploadFile.splice(index, 1);
    setUploadFile([...uploadFile]);
  };

  const changingUploadFilesCheck = () => {
    setIsUploadFiles(!isUploadFiles);

    if (isUploadFiles) {
      setUploadFile([]);
    }
  };

  useEffect(() => {
    setUploadFile([...uploadFile, ...acceptedFiles]);
  }, [acceptedFiles]);
  return (
    <>
      <Breadcrumb pageTitle="Contact Us">
        <BreadcrumbItem Link="/contact" Text="Contact" />
      </Breadcrumb>

      <BannerImage imagesrc={banner}></BannerImage>

      <PageContainer>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <OutlindedFieldContainer>
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <FieldContainer>
                  <StyledTextField
                    label="First name"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: true, maxLength: 40 })}
                    inputProps={nameProps}
                    name="firstName"
                    error={errors.firstName ? true : false}
                  />
                  <span className="error-message">
                    {errors.firstName && "First name is required"}
                  </span>
                </FieldContainer>
                <FieldContainer>
                  <StyledTextField
                    label="Last name"
                    variant="outlined"
                    type="text"
                    inputRef={register({ required: true, maxLength: 40 })}
                    inputProps={nameProps}
                    name="lastName"
                    error={errors.lastName ? true : false}
                  />
                  <span className="error-message">
                    {errors.lastName && "Last name is required"}
                  </span>
                </FieldContainer>
                <FieldContainer>
                  <StyledTextField
                    label="Mobile"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      pattern: /[0][4][0-9]{8}/,
                    })}
                    inputProps={mobileProps}
                    name="mobile"
                    error={errors.mobile ? true : false}
                  />
                  <span className="error-message">
                    {errors.mobile && "10 digits mobile number is required"}
                  </span>
                </FieldContainer>
                <FieldContainer>
                  <StyledTextField
                    label="Email"
                    variant="outlined"
                    type="text"
                    inputRef={register({
                      required: true,
                      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    name="email"
                    error={errors.email ? true : false}
                  />
                  <span className="error-message">
                    {errors.email && "Email address is required"}
                  </span>
                </FieldContainer>
                <FieldContainer>
                  <StyledFormGroup row>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="isGeneralEnquiry"
                        />
                      }
                      label="General enquiry"
                    />
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          inputRef={register({ required: false })}
                          name="isAppointment"
                          checked={isAppointment}
                          onClick={() => setIsAppointment(!isAppointment)}
                        />
                      }
                      label="Request appointment"
                    />
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={isUploadFiles}
                          onChange={() => changingUploadFilesCheck()}
                          inputRef={register({ required: false })}
                          name="isUploadFiles"
                        />
                      }
                      label="Upload files"
                    />
                  </StyledFormGroup>
                </FieldContainer>
                <FieldContainer>
                  <StyledTextField
                    label="Message"
                    variant="outlined"
                    multiline
                    rows={6}
                    type="text"
                    inputRef={register({ required: true, maxLength: 1000 })}
                    inputProps={messageProps}
                    name="message"
                    error={errors.message ? true : false}
                  />
                  <span className="error-message">
                    {errors.message && "Message is required"}
                  </span>
                </FieldContainer>
                <FieldContainer>
                  <div hidden={!isUploadFiles}>
                    <FileContainer
                      {...getRootProps({
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                      })}
                    >
                      <FormText>Drop files here or click to upload.</FormText>
                      <input {...getInputProps()} />
                    </FileContainer>
                    <List dense={false}>
                      {uploadFile.map((file) => (
                        <ListItem key={file.path}>
                          <ListItemText primary={file.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => deleteFile(file.name)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </FieldContainer>
                <Collapse in={openSucceed}>
                  <StyledAlert
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenSucceed(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Email sent successfully
                  </StyledAlert>
                </Collapse>
                <Collapse in={openError}>
                  <StyledAlert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenError(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Error occurred. Please contact us.
                  </StyledAlert>
                </Collapse>
                <Collapse in={openFileSize}>
                  <StyledAlert
                    severity="warning"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenFileSize(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Total file size should be less than 4MB. Please send files
                    through an email.
                  </StyledAlert>
                </Collapse>

                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress className={classes.top} />
                  ) : null}
                  Send
                </StyledButton>
              </form>
            </OutlindedFieldContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoBox>
              <StyledAnchor onClick={() => setIsAppointment(true)}>
                <OpenHourIcon />
                <InfoText>By appointment only</InfoText>
              </StyledAnchor>
            </InfoBox>
            <InfoBox>
              <StyledAnchor
                href="https://www.google.com/maps/place/Gippsland+Prosthodontics/@-38.1618264,145.939842,17z/data=!3m1!4b1!4m5!3m4!1s0x6b29970f09a6e991:0xe37828d3aeacb131!8m2!3d-38.1618264!4d145.9420307"
                target="_blank"
              >
                <MapIcon />
                <InfoText>132 Albert Rd, Warragul VIC 3820</InfoText>
              </StyledAnchor>
            </InfoBox>
            <InfoBox>
              <StyledAnchor href="mailto:reception@gippslandprosthodontics.com.au">
                <EmailIcon />
                <InfoText>reception@gippslandprosthodontics.com.au</InfoText>
              </StyledAnchor>
            </InfoBox>
            <InfoBox>
              <StyledAnchor href="tel:03 5642 6947">
                <MobileIcon />
                <InfoText>03 5642 6947</InfoText>
              </StyledAnchor>
            </InfoBox>
            <InfoBox>
              <QRTitle>Website QR code</QRTitle>
              <QrCodeContainer>
                <QrCodeBox>
                  <QrCode />
                </QrCodeBox>
              </QrCodeContainer>
            </InfoBox>
          </Grid>
        </Grid>
      </PageContainer>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <StyledIFrame
            title="Gippsland Prosthodontics location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18113.649100219453!2d145.92774445106025!3d-38.16396075341916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b29970f09a6e991%3A0xe37828d3aeacb131!2sGippsland%20Prosthodontics!5e0!3m2!1sen!2sau!4v1618323513408!5m2!1sen!2sau"
            frameBorder="0"
            scrolling="no"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Contact;
