import styled, { css } from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import { Button, FormGroup, TextField, Grid } from "@material-ui/core";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BsClock, BsMap } from "react-icons/bs";
import { AiOutlineMail, AiOutlineQrcode } from "react-icons/ai";
import { BiMobileVibration } from "react-icons/bi";
import Alert from "@material-ui/lab/Alert";

export const InfoBox = styled.div`
  text-align: center;
  margin: 1rem auto 2rem;
  padding-left: 0.5rem;
  @media (min-width: ${breakpoints.md}) {
    margin: 0.5rem auto 1rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 1rem auto 2rem;
  }
`;
export const InfoText = styled.span`
  font-weight: 300;
  font-size: 1rem;
  display: block;
  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.25rem;
  }
`;
export const StyledAnchor = styled.a`
  font-size: 1rem;
  color: ${colors.dark};
  text-decoration: none;
  display: block;
  &:hover {
    color: ${colors.primary};
  }
`;
export const OutlindedFieldContainer = styled.div`
  position: relative;
  padding-bottom: 0.5rem;
  .MuiOutlinedInput-notchedOutline {
    top: 0px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #158da7;
    border-width: 2px;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background-color: #fff;
    padding: 0 0.375rem;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #158da7;
  }
  .error-message {
    display: block;
    color: #f44336;
    padding-left: 0.875rem;
    margin-top: 3px;
    font-size: 0.75rem;
  }
`;
export const StyledButton = styled(Button)`
  background-color: #158da7;
  padding: 1rem 0;
  &:hover {
    background-color: #0bb4ac;
  }
`;

export const FormText = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 2rem 0.875rem;
  color: ${colors.dentGray};
`;

export const FileContainer = styled.div`
  border: 2px dashed rgba(0, 0, 0, 0.23);
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  margin: 0.5rem 0;
  background: ${colors.lightGray};
`;
const IconStyle = css`
  color: #333;
  &:hover {
    color: ${colors.primary};
  }
`;
export const DeleteIcon = styled(RiDeleteBin5Line)`
  ${IconStyle}
`;

export const StyledFormGroup = styled(FormGroup)`
  flex-direction: column;
  @media (min-width: ${breakpoints.sm}) {
    flex-direction: row;
  }
`;
export const OpenHourIcon = styled(BsClock)`
  ${IconStyle}
  font-size: 1.75rem;
  margin-bottom: 0.875rem;
`;
export const EmailIcon = styled(AiOutlineMail)`
  ${IconStyle}
  font-size: 1.75rem;
  margin-bottom: 0.875rem;
`;
export const MobileIcon = styled(BiMobileVibration)`
  ${IconStyle}
  font-size: 1.75rem;
  margin-bottom: 0.875rem;
`;
export const QRTitle = styled.span`
  font-weight: 700;
  font-size: 1rem;
  display: block;
  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.25rem;
  }
`;
export const QrCodeIcon = styled(AiOutlineQrcode)`
  ${IconStyle}
  font-size: 1.75rem;
  margin-bottom: 0.875rem;
`;
export const QrCodeBox = styled.div`
  width: 8rem;
  margin: 1rem 0;
`;
export const QrCodeContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const MapIcon = styled(BsMap)`
  ${IconStyle}
  font-size: 1.75rem;
  margin-bottom: 0.875rem;
`;
export const StyledIFrame = styled.iframe`
  border: none;
  overflow: hidden;
  height: 500px;
  width: 100%;
  margin: 3.5rem 0 0;
`;
export const FieldContainer = styled.div`
  padding: 0.75rem 0;
`;
export const StyledTextField = styled(TextField)`
  width: 100%;
`;
export const StyledAlert = styled(Alert)`
  position: absolute;
  bottom: -3rem;
  right: 0;
`;
